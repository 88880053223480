<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          @search="onSearch"
          ref="searchBar"
          searchLabel="Search by hazard name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        :align="this.$vuetify.breakpoint.mdAndUp ? 'right' : ''"
        cols="auto"
      >
        <CorrectiveActionsReportFilter
          ref="filters"
          :users="users"
          :filters="filters"
          @applyFilters="applyFilter($event)"
        />
      </v-col>
      <v-col
        :align="this.$vuetify.breakpoint.mdAndUp ? 'right' : ''"
        cols="auto"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="exportCorrectiveActions()"
              class="msaBlue white--text"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          Export
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text py-5">
            Corrective Actions
            <v-badge
              :content="itemsCount"
              :value="itemsCount"
              bordered
              class="ml-2"
              inline
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="itemsCount"
            must-sort
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ item.createdAt | filterAsLocalDate('LLL dd, yyyy') }}
            </template>

            <template v-slot:[`item.hazardName`]="{ item }">
              <span>
                {{ item.hazardName | truncate }}
              </span>
            </template>

            <template v-slot:[`item.hazardControl`]="{ item }">
              <span>
                {{ item.hazardControl | truncate }}
              </span>
            </template>

            <template v-slot:[`item.formLabel`]="{ item }">
              <span
                @click="goToFormDetails(item)"
                class="msaBlue--text"
                style="cursor: pointer"
              >
                {{ item.formLabel | truncate }}
              </span>
            </template>

            <template v-slot:[`item.fullname`]="{ item }">
              <span
                v-if="item.userStatus === 'Active'"
                @click="goToUserDetails(item.userId)"
                class="msaBlue--text"
                style="cursor: pointer"
              >
                {{ item.fullname | truncate }}
              </span>
              <span v-else class="red--text">
                {{ item.fullname | truncate }}
              </span>
            </template>

            <template v-slot:[`item.resolvedAt`]="{ item }">
              {{ item.lastResolvedAt | filterAsLocalDate('LLL dd, yyyy') }}
            </template>

            <template v-slot:[`item.correctiveActionDetails`]="{ item }">
              <span v-for="obj in getStatusObjects(item)" :key="obj.id">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="obj.color" small>
                      {{ obj.icon }}
                    </v-icon>
                  </template>
                  <span> {{ obj.message }} </span>
                </v-tooltip>
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CorrectiveActionsReportFilter from '../components/Filters/CorrectiveActionsReportFilter.vue';
import SearchBar from '../components/SearchBar.vue';

export default {
  name: 'CorrectiveActionsReportPage',
  components: {
    CorrectiveActionsReportFilter,
    SearchBar,
  },
  filters: {
    truncate(text, length = 50) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
  },
  data() {
    return {
      itemsCount: 0,
      items: [],
      filters: this.$constants.CORRECTIVE_ACTION_REPORT_DEFAULT_FILTERS(),
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      headers: [
        {
          text: 'Created Date',
          value: 'createdAt',
        },
        {
          text: 'Hazard',
          value: 'hazardName',
        },
        {
          text: 'Control',
          value: 'hazardControl',
        },
        {
          text: 'Form',
          value: 'formLabel',
        },
        {
          text: 'Form ID',
          value: 'formId',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'formStatus',
        },
        {
          text: 'Resolved Date',
          value: 'resolvedAt',
        },
        {
          text: 'Assigned To',
          value: 'fullname',
        },
        {
          text: 'Information',
          value: 'correctiveActionDetails',
          sortable: false,
        },
      ],
      users: [],
    };
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.filters.searchHazard != '') {
        this.$refs.searchBar.setSearch(this.filters.searchHazard);
      }
      this.$refs.filters.setFilters();
    });
  },
  methods: {
    getStatusObjects(item) {
      const displayObjects = [];
      const genObj = (message, color, icon) => ({ message, color, icon });

      if (item.hasAudit) {
        displayObjects.push(
          genObj('Form has been audited.', 'green', 'mdi-check'),
        );
      }

      if (
        item.correctiveActionStatus ==
        this.$constants.CORRECTIVE_ACTION_STATUS.UNRESOLVED
      ) {
        displayObjects.push(
          genObj(
            'Form has unresolved corrective actions.',
            'red',
            'mdi-alert-circle',
          ),
        );
      }

      if (
        item.correctiveActionStatus ==
        this.$constants.CORRECTIVE_ACTION_STATUS.RESOLVED
      ) {
        displayObjects.push(
          genObj(
            'Form has resolved corrective actions.',
            'green',
            'mdi-alert-circle',
          ),
        );
      }

      return displayObjects;
    },
    onSearch(data) {
      this.filters.searchHazard = data;
      this.options.page = 1;
      this.updateUrl();
    },
    applyFilter(event) {
      this.filters = JSON.parse(JSON.stringify(event));
      this.options.page = 1;
      this.updateUrl();
    },
    async getItems() {
      const url = 'get-corrective-actions-for-organization?format=json';
      const params = {
        loaderText: 'Loading...',
        options: this.options,
        filters: this.filters,
      };

      await this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
        })
        .catch((error) => error);
    },
    getUsersWithCA() {
      const url = 'get-users-with-corrective-actions-by-org?format=json';
      const params = {
        filters: {
          userStatus: this.filters.userStatus,
        },
      };

      this.$axios.post(url, params).then((response) => {
        this.users = response.data;
      });
    },
    exportCorrectiveActions() {
      const url = 'export-corrective-actions-for-organization?format=json';
      const params = {
        loaderText: 'Loading...',
        options: this.options,
        filters: this.filters,
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.downloadFile(
          response.data,
          'Corrective Actions Export.xlsx',
        );
      });
    },
    goToUserDetails(userId) {
      this.$router.push({
        name: 'UserDetails',
        params: { id: userId },
      });
    },
    goToFormDetails(item) {
      this.$store.commit('updateSelectedFormInstance', {
        name: item.formLabel,
      });

      this.$router.push({
        name: 'FormDetails',
        params: { id: item.formId },
      });
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(
        query,
        this.$constants.CORRECTIVE_ACTION_REPORT_DEFAULT_FILTERS(),
      );
      this.options = this.$helpers.deserializeOptions(query, 'createdAt', true);

      this.init();
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.CORRECTIVE_ACTION_REPORT_DEFAULT_FILTERS(),
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        'createdAt',
        true,
      );
      params = { ...params, ...options };

      this.$router
        .push({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    async init() {
      await this.getItems();
      this.getUsersWithCA();
    },
  },
};
</script>
