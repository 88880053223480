<template>
  <v-container class="pa-0 mx-0" fluid>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              v-blur
              :color="filterApplied ? 'warning' : 'msaBlue'"
              class="white--text"
            >
              <v-icon> mdi-filter </v-icon>
            </v-btn>
          </template>
          Filter
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title class="headline msaBlue white--text">
          <v-row>
            <v-col> Filters </v-col>
            <v-col class="shrink">
              <v-btn @click="dialog = false" dark icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row class="my-3" no-gutters>
            <v-col class="font-weight-bold" cols="12"> Form Title </v-col>
            <v-col>
              <v-text-field
                v-model="editedFilters.formLabel"
                clearable
                dense
                hide-details
                outlined
                placeholder="Enter a form title"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="my-3" no-gutters>
            <v-col class="font-weight-bold" cols="12"> Created Date </v-col>
            <v-col class="mr-2">
              <v-menu
                v-model="fromCreatedDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="editedFilters.createdFrom"
                    v-on="on"
                    append-icon="mdi-calendar"
                    clearable
                    dense
                    hide-details
                    label="From"
                    outlined
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="editedFilters.createdFrom"
                  @input="fromCreatedDateMenu = false"
                  hide-details
                  no-title
                  :max="createdFromMax"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="toCreatedDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="editedFilters.createdTo"
                    v-on="on"
                    append-icon="mdi-calendar"
                    clearable
                    dense
                    hide-details
                    label="To"
                    outlined
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="editedFilters.createdTo"
                  :min="createdToMin"
                  @input="toCreatedDateMenu = false"
                  hide-details
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="my-3" no-gutters>
            <v-col class="font-weight-bold" cols="12"> Resolved Date </v-col>
            <v-col class="mr-2">
              <v-menu
                v-model="fromResolvedDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="editedFilters.resolvedFrom"
                    v-on="on"
                    append-icon="mdi-calendar"
                    clearable
                    dense
                    hide-details
                    label="From"
                    outlined
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="editedFilters.resolvedFrom"
                  @input="fromResolvedDateMenu = false"
                  hide-details
                  no-title
                  :max="resolvedFromMax"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="toResolvedDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="editedFilters.resolvedTo"
                    v-on="on"
                    append-icon="mdi-calendar"
                    clearable
                    dense
                    hide-details
                    label="To"
                    outlined
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="editedFilters.resolvedTo"
                  :min="resolvedToMin"
                  @input="toResolvedDateMenu = false"
                  hide-details
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="my-3" no-gutters>
            <v-col class="font-weight-bold" cols="12"> Users </v-col>
            <v-col>
              <CustomizedAutoComplete
                :appendIcon="''"
                :items="userList"
                :value="editedFilters.assignedTo"
                @change="editedFilters.assignedTo = $event"
                clearable
                deletable-chips
                dense
                hide-details
                item-text="fullname"
                item-value="ID"
                label="Select user(s)"
                multiple
                outlined
              />
            </v-col>
          </v-row>

          <v-row class="my-3" no-gutters>
            <v-col class="font-weight-bold" cols="12"> User Status </v-col>
            <v-col>
              <v-btn-toggle
                v-model="editedFilters.userStatus"
                color="msaBlue"
                dense
                mandatory
                style="width: 100%"
              >
                <v-btn :value="2" class="text-capitalize" style="width: 33%">
                  All
                </v-btn>
                <v-btn :value="1" class="text-capitalize" style="width: 33%">
                  Active
                </v-btn>
                <v-btn :value="0" class="text-capitalize" style="width: 33%">
                  Inactive
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row class="my-3" no-gutters>
            <v-col class="font-weight-bold" cols="12"> Form Status </v-col>
            <v-col>
              <v-btn-toggle
                v-model="editedFilters.formStatus"
                color="msaBlue"
                dense
                mandatory
                multiple
                style="width: 100%"
              >
                <v-btn
                  :value="$constants.FORM_INSTANCE_STATUS.SUBMITTED"
                  class="text-capitalize"
                  style="width: 33%"
                >
                  Submitted
                </v-btn>
                <v-btn
                  :value="$constants.FORM_INSTANCE_STATUS.REVIEWED"
                  class="text-capitalize"
                  style="width: 33%"
                >
                  Reviewed
                </v-btn>
                <v-btn
                  :value="$constants.FORM_INSTANCE_STATUS.FINALIZED"
                  class="text-capitalize"
                  style="width: 33%"
                >
                  Finalized
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row class="my-3" no-gutters>
            <v-col class="font-weight-bold" cols="12"> Has Form Audit </v-col>
            <v-col>
              <v-btn-toggle
                v-model="editedFilters.formAuditStatus"
                color="msaBlue"
                dense
                mandatory
                style="width: 100%"
              >
                <v-btn :value="2" class="text-capitalize" style="width: 33%">
                  All
                </v-btn>
                <v-btn :value="1" class="text-capitalize" style="width: 33%">
                  Yes
                </v-btn>
                <v-btn :value="0" class="text-capitalize" style="width: 33%">
                  No
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row class="my-3" no-gutters>
            <v-col class="font-weight-bold" cols="12">
              Corrective Action Status
            </v-col>
            <v-col>
              <v-btn-toggle
                v-model="editedFilters.correctiveActionStatus"
                color="msaBlue"
                dense
                mandatory
                multiple
                style="width: 100%"
              >
                <v-btn
                  :value="$constants.CORRECTIVE_ACTION_STATUS.RESOLVED"
                  class="text-capitalize"
                  style="width: 33%"
                >
                  Resolved
                </v-btn>
                <v-btn
                  :value="$constants.CORRECTIVE_ACTION_STATUS.UNRESOLVED"
                  class="text-capitalize"
                  style="width: 33%"
                >
                  Unresolved
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="clear" color="red" text> clear </v-btn>
          <v-btn @click="apply" class="msaBlue white--text"> apply </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CustomizedAutoComplete from '../CustomizedAutoComplete.vue';

export default {
  name: 'CorrectiveActionsReportFilter',
  components: {
    CustomizedAutoComplete,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.setFilters();
      }
    },
  },
  data() {
    return {
      dialog: false,
      fromResolvedDateMenu: false,
      fromCreatedDateMenu: false,
      toCreatedDateMenu: false,
      toResolvedDateMenu: false,
      editedFilters: this.$constants.CORRECTIVE_ACTION_REPORT_DEFAULT_FILTERS(),
    };
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(
          this.$constants.CORRECTIVE_ACTION_REPORT_DEFAULT_FILTERS(),
        )
      );
    },
    createdToMin() {
      return this.editedFilters.createdFrom == '' ||
        this.editedFilters.createdFrom == null
        ? undefined
        : this.editedFilters.createdFrom;
    },
    createdFromMax() {
      return this.editedFilters.createdTo == '' ||
        this.editedFilters.createdTo == null
        ? undefined
        : this.editedFilters.createdTo;
    },
    resolvedToMin() {
      return this.editedFilters.resolvedFrom == '' ||
        this.editedFilters.resolvedFrom == null
        ? undefined
        : this.editedFilters.resolvedFrom;
    },
    resolvedFromMax() {
      return this.editedFilters.resolvedTo == '' ||
        this.editedFilters.resolvedTo == null
        ? undefined
        : this.editedFilters.resolvedTo;
    },
    userList() {
      switch (this.editedFilters.userStatus) {
        case 0:
          return this.users.filter((user) => !user.userStatus);
        case 1:
          return this.users.filter((user) => user.userStatus);
        default:
          return this.users;
      }
    },
  },
  methods: {
    clear() {
      this.editedFilters =
        this.$constants.CORRECTIVE_ACTION_REPORT_DEFAULT_FILTERS();
      this.apply();
    },
    apply() {
      this.fixNulls();
      this.$emit('applyFilters', this.editedFilters);
      this.dialog = false;
    },
    fixNulls() {
      if (this.editedFilters.formLabel == null) {
        this.editedFilters.formLabel = '';
      }

      if (this.editedFilters.searchHazard == null) {
        this.editedFilters.searchHazard = '';
      }

      if (this.editedFilters.createdFrom == null) {
        this.editedFilters.createdFrom = '';
      }

      if (this.editedFilters.createdTo == null) {
        this.editedFilters.createdTo = '';
      }

      if (this.editedFilters.resolvedFrom == null) {
        this.editedFilters.resolvedFrom = '';
      }

      if (this.editedFilters.resolvedTo == null) {
        this.editedFilters.resolvedTo = '';
      }
    },
    setFilters() {
      this.editedFilters = JSON.parse(JSON.stringify(this.filters));
    },
  },
};
</script>
